export default {
  error_occurred: 'Entschuldigung, es ist ein Fehler aufgetreten. Bitte erneut versuchen.',
  new_content_available: 'Es gibt eine neue Seitenaktualisierung, bitte klicken Sie auf "Aktualisieren", um die Seite neu zu laden.',
  refresh_button_title: 'Aktualisieren',
  force_reload_message: 'Entschuldigung, es ist ein Fehler aufgetreten. Unser Team arbeitet bereits an der Lösung. Versuchen Sie, die Seite neu zu laden.',
  sign_up: 'Konto erstellen',
  service: 'Service',
  employee: 'Spezialist',
  select_employee: 'Person auswählen',
  employees: 'Spezialisten',
  services: 'Dienstleistungen',
  duration: 'Zeitdauer',
  from: 'Von',
  select_date: 'Datum auswählen',
  no_account_question: 'Sie haben noch kein Konto?',
  name: 'Vorname',
  surname: 'Na̱chname',
  name_and_surname: 'Vorname und Nachname',
  phone: 'Telefon',
  password: 'Passwort',
  field_is_required: 'Dieses Feld ist erforderlich.',
  value_is_too_long: 'Der eingegebene Wert ist zu lang.',
  value_is_too_short: 'Der eingegebene Wert ist zu kurz.',
  signup_was_successful: 'Registrierung erfolgreich abgeschlossen. Sie werden in das Panel eingeloggt.',
  signup_has_failed: 'Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.',
  email_is_taken: 'Ein Konto mit der angegebenen E-Mail-Adresse ist bereits vorhanden. Wenn Sie Ihr Passwort vergessen haben, nutzen Sie die Erinnerungsoption.',
  email_or_password_incorrect: 'Die angegebene E-Mail-Adresse oder das angegebene Passwort ist nicht korrekt. Wenn Sie Ihr Passwort vergessen haben, nutzen Sie die Erinnerungsoption.',
  account_not_found: 'Konto nicht gefunden.',
  page_not_found: 'Seite nicht gefunden :(',
  go_back_to_main_page: 'Bitte gehen Sie zurück zur Startseite und versuchen Sie es erneut.',
  bookings: 'Buchungen',
  account: 'Mein Konto',
  date: 'Datum',
  cancel: 'Abbrechen',
  date_from: 'Datum von',
  date_to: 'Datum bis',
  nothing_found_here: 'Nichts gefunden.',
  old_password: 'Altes Passwort ',
  remove: 'Löschen',
  phone_is_invalid: 'Die Telefonnummer ist nicht korrekt.',
  book: 'Buchen',
  sign_up_2: 'Konto erstellen',
  log_in: 'Anmelden',
  your_email_address: 'Ihre E-Mail-Adresse',
  select: 'Bitte wählen',
  filters: 'Filter',
  expand: 'Erweitern',
  collapse: 'Verstecken',
  bookings_drawer_title: 'Wählen Sie Filter aus, um nur die gewünschten Reservierungen anzuzeigen.',
  clear_filters: 'Filter entfernen',
  show_more: 'Mehr anzeigen',
  dont_you_remember_password: 'Passwort vergessen?',
  password_information: 'Das Passwort sollte aus mindestens 8 Zeichen bestehen, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
  forgotten_password_button: 'Senden Sie einen Link, um Ihr Passwort zu ändern',
  do_you_already_have_an_account: 'Haben Sie bereits ein Konto?',
  reset_password_title: 'Neues Passwort erstellen',
  reset_password_subtitle: 'Bitte geben Sie Ihr neues Passwort ein.',
  reset_password_button: 'Das neue Passwort speichern',
  profile: 'Profil',
  logout: 'Abmelden',
  cancel_booking: 'Ihre Buchung stornieren',
  status_approved: 'Akzeptiert',
  status_payment: 'Warten auf Zahlung',
  status_done: 'Hat stattgefunden',
  status_cancelled: 'Storniert',
  cancel_booking_dialog_title: 'Möchten Sie Ihre Buchung stornieren?',
  cancel_booking_dialog_information: 'Diese Aktion kann nicht rückgängig gemacht werden!',
  cancel_booking_dialog_button: 'Ja, Ihre Buchung stornieren',
  my_profile: 'Mein Profil',
  my_data: 'Meine Daten',
  upload_new_photo: 'Neues Foto hinzufügen',
  birthday: 'Geburtstag',
  save_changes: 'Änderungen speichern',
  phone_number: 'Telefonnummer',
  directional: 'Vorwahlnummer',
  save_new_password: 'Das neue Passwort speichern',
  new_password: 'Neues Passwort',
  password_change: 'Passwort ändern',
  view: 'Siehe',
  services_2: 'Service',
  accept_terms_and_conditions_1: 'Indem ich die Option unten wähle, stimme ich zu',
  accept_terms_and_conditions_2: 'Nutzungsbedingungen',
  accept_terms_and_conditions_3: 'und bestätige das gelesen zu haben',
  accept_terms_and_conditions_4: 'Datenschutzerklärung.',
  to: 'Zu ',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Anmelden',
  email_is_incorrect: 'Die E-Mail-Adresse ist nicht korrekt.',
  logged_out: 'Sie wurden abgemeldet',
  booking_dialog_date_is_already_taken: 'Das gewählte Datum ist nicht verfügbar. Bitte wählen Sie ein anderes verfügbares Datum.',
  data_search: 'Datensuche. Bitte warten...',
  no_employees: 'Keine Spezialisten',
  no_services: 'Keine Dienstleistungen',
  no_categories: 'Keine Kategorien',
  no_bookings: 'Buchung nicht gefunden.',
  no_subscriptions: 'Abonnement nicht gefunden.',
  female: 'Frau',
  male: 'Mann',
  other: 'Andere',
  no_numeric_in_password: 'Das Passwort enthält kein numerisches Zeichen.',
  no_lowercase_in_password: 'Das Passwort enthält keinen Kleinbuchstaben.',
  no_uppercase_in_password: 'Das Passwort enthält keinen Großbuchstaben.',
  no_special_character: 'Das Passwort enthält kein Sonderzeichen.',
  min_n_characters: 'Die Mindestanzahl an Zeichen ist @{min}.',
  max_n_characters: 'Die maximale Anzahl von Zeichen ist @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'Passwort wurde nicht geändert. Der Link kann abgelaufen sein.',
  reset_password_success_title: 'Passwort wurde geändert.',
  reset_password_success_text: 'Sie können sich mit Ihrem neuen Passwort anmelden.',
  account_activation: 'Kontoaktivierung',
  account_activation_success: 'Das Konto wurde aktiviert.',
  account_activation_fail: 'Bei der Aktivierung Ihres Kontos ist ein Problem aufgetreten. Wahrscheinlich ist das Konto bereits aktiv oder der Link abgelaufen.',
  account_activation_processing: 'Das Konto wird aktiviert. Bitte warten...',
  account_activation_resend_title: 'Das Konto wurde nicht aktiviert',
  account_activation_resend_text: 'Aktivieren Sie das Konto durch Klicken auf den Aktivierungslink in der gesendeten E-Mail.',
  account_activation_resend_button: 'Erneut senden',
  confirm_send_activate_link_dialog_subtitle: 'Wir haben eine Nachricht mit dem Link zur Aktivierung des Kontos an die angegebene E-Mail-Adresse gesendet.',
  file_is_too_large: 'Ausgewählte Datei ist zu groß.',
  appointment_title: 'Wählen Sie die Person, das Datum und die Uhrzeit der Buchung aus',
  others: 'Andere',
  countryLabel: 'Land',
  requiredMessages: 'Eine Telefonnummer ist erforderlich',
  date_of_visit: 'Datum',
  hour_of_visit: 'Zeit',
  thank_you_for_booking: 'Ihre Buchung',
  no_free_hours: 'Keine freien Plätze für Termine.',
  any_person: 'Zufällige Auswahl',
  password_changed: 'Passwort wurde geändert',
  incorrect_old_password: 'Altes Passwort ungültig',
  error404: 'Error 404 Fehlermeldung',
  minutes: 'Min.',
  hours: 'Std.',
  days: 'Tage',
  ok: 'OK',
  web_page_temporary_disabled: 'Wir bitten um Entschuldigung! Die Website ist vorübergehend deaktiviert.',
  profile_update_success: 'Ihre Daten wurden gespeichert.',
  form_is_invalid: 'Das Formular enthält Fehler. Überprüfen Sie die markierten Felder und versuchen Sie es erneut.',
  search: 'Suchen',
  pay_online_button_title: 'Online bezahlen',
  account_not_activated_info: 'Die E-Mail-Adresse wurde nicht bestätigt. Bestätigen Sie die E-Mail-Adresse mit dem Link in der erhaltenen Nachricht.',
  paid: 'Bezahlt',
  payments: 'Zahlung',
  payment_service: 'Service',
  payment_date: 'Datum',
  payment_price: 'Preis',
  payment_success_title: 'Ihre Zahlung war erfolgreich. Vielen Dank!',
  payment_fail_title: 'Die Zahlung ist fehlgeschlagen, bitte versuchen Sie es erneut.',
  pay_again: 'Nochmal bezahlen',
  newsletter_subscribe_success: 'Vielen Dank, die E-Mail-Adresse wurde bestätigt.',
  newsletter_subscribe_fail: 'Bei der Aktivierung Ihrer E-Mail-Adresse ist ein Problem aufgetreten. Anscheinend wurde die E-Mail-Adresse bereits bestätigt oder der Link ist abgelaufen.',
  newsletter_send_again: 'Erneut senden',
  newsletter_send_again_success: 'Der Aktivierungslink wurde erneut versendet.',
  send_again_fail: 'Beim Generieren eines neuen Aktivierungslinks ist ein Problem aufgetreten, möglicherweise ist Ihre E-Mail-Adresse bereits aktiv oder Sie haben den Link gerade erst generiert.',
  newsletter_email_is_used: 'Die E-Mail-Adresse ist bereits in der Liste. Wurde die Adresse nicht bestätigt, kann der Aktivierungslink erneut versendet werden.',
  newsletter_unsubscribe_success: 'Vielen Dank, die E-Mail-Adresse wurde gelöscht.',
  newsletter_unsubscribe_fail: 'Beim Löschen der E-Mail-Adresse ist ein Problem aufgetreten. Möglicherweise wurde die E-Mail-Adresse bereits gelöscht oder der Link ist abgelaufen.',
  booking_payments: 'Buchungen',
  simple_store_product_transactions: 'Produkte',
  payment_type_select_box: 'Bitte wählen Sie eine Zahlungsart',
  payment_description: 'Beschreibung',
  add_to_cart: 'in den Warenkorb legen',
  remove_from_cart: 'Aus dem Warenkorb entfernen',
  save: 'Speichern',
  bookings_cart_summary: 'Buchungsübersicht',
  today: 'Heute',
  fill_booking_details_title: 'Füllen Sie das Buchungsformular aus',
  create_an_account_question: 'Erstellen Sie ein Konto und senden Sie das Passwort an die E-Mail-Adresse',
  value_is_incorrect: 'Der eingegebene Wert ist ungültig.',
  first_available_date: 'Verfügbare Termine in',
  service_location_1: 'Adresse',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Andere',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Standort',
  booking_not_found: 'Buchung nicht gefunden.',
  booking_canceled: 'Die Buchung wurde storniert.',
  booking_canceled_fail: 'Die Buchung kann nicht mehr storniert werden.',
  day: 'Tag',
  week: 'Woche',
  month: 'Monat',
  year: 'Jahr',
  booking_plural_1: 'Buchung',
  booking_plural_2: 'Buchungen',
  booking_plural_3: 'Buchungen',
  booking_plural_4: 'Buchungen',
  booking_plural_5: 'Buchungen',
  day_plural_1: 'Tag',
  day_plural_2: 'Tage',
  day_plural_3: 'Tage',
  day_plural_4: 'Tage',
  day_plural_5: 'Tage',
  week_plural_1: 'Woche',
  week_plural_2: 'Wochen',
  week_plural_3: 'Wochen',
  week_plural_4: 'Wochen',
  week_plural_5: 'Wochen',
  month_plural_1: 'Monat',
  month_plural_2: 'Monate',
  month_plural_3: 'Monate',
  month_plural_4: 'Monate',
  month_plural_5: 'Monate',
  year_plural_1: 'Jahr',
  year_plural_2: 'Jahre',
  year_plural_3: 'Jahre',
  year_plural_4: 'Jahre',
  year_plural_5: 'Jahre',
  tax: 'Steuernummer',
  tax_prefix: 'Vo̱rwahl',
  get_company_data: 'Suchen',
  get_company_data_failed: 'Daten konnten nicht abgerufen werden.',
  company_name: 'Name des Unternehmens',
  street_and_number: 'Straße und Hausnummer',
  postcode: 'PLZ',
  city: 'Stadt',
  country: 'Land',
  subscribe: 'Abonnieren',
  recurrent_fee_info: 'Abrechnung alle',
  one_time_fee_info: 'Einmalige Zahlung.',
  new_account_title: 'Neues Konto erstellen',
  payment_data_title: 'Zahlungsdaten',
  billing_data_checkbox_question: 'Rechnungsdetails',
  confirmation_modal_header: 'Die Aktion muss bestätigt werden',
  confirmation_modal_warning: 'Sind Sie sicher, dass Sie fortfahren wollen?',
  no_way_to_undo_action: 'Diese Aktion kann nicht rückgängig gemacht werden!',
  delete_payment_method: 'Karte entfernen',
  add_payment_method: 'Karte hinzufügen',
  fill_card_details: 'Bitte geben Sie die Zahlungsinformationen ein.',
  subscriptions: 'Abonnements',
  subscription: 'Abonnement',
  invoice_number: 'Rechnung',
  number: 'Nummer',
  status: 'Status',
  details: 'Details',
  created_at: 'Erstellt',
  subscription_status_initiated: 'Unbezahlt',
  subscription_status_active: 'Aktiv',
  subscription_status_inactive: 'Inaktiv',
  subscription_status_canceled: 'Storniert',
  ends_at: 'Gültigkeit',
  ends_at_default: 'Gültig bis zum Ende der Zeit',
  select_subscription_to_pay: 'Bitte wählen Sie ein Abonnement aus, das Sie bezahlen möchten.',
  pay_with_subscription: 'Im Abonnement bezahlen',
  bookings_paid_by_subscription_success_message: 'Bezahlt',
  bookings_not_paid_by_subscription_error_message: 'Die Buchung konnte nicht mit dem ausgewählten Abonnement bezahlt werden.',
  stripe: 'Stripe',
  provider_tag: 'Methode',
  card: 'Karte',
  cash: 'Kasse',
  transfer: 'Banküberweisung',
  promo_code_label: 'Rabattcode',
  promo_code_invalid_message: 'Rabattcode ist ungültig.',
  payment_method_setup_error: 'Es gibt ein Problem mit Ihrer Karte. Ihre Bank hat es abgelehnt, die Karte für spätere Transaktionen zu autorisieren. Bitte wenden Sie sich an Ihre Bank.',
  active_subscription_missing: 'Für die Buchung dieses Dienstes ist ein aktives Abonnement erforderlich. Sie haben kein Abonnement zu verwenden.',
  area_code: 'Vo̱rwahl',
  usage: 'Anwendung',
  create_account_in_stripe_failed: 'Vom Zahlungsanbieter abgelehnte Daten. Bitte prüfen Sie, ob die Daten korrekt eingegeben wurden (Steuer-ID, Telefonnummer, E-Mail-Adresse).',
  additional_auth_required: 'Ihre Bank benötigt eine zusätzliche Autorisierung',
  general_payment_error: 'Es gibt ein Problem mit Ihrer Zahlung. Möglicherweise unterstützt Ihre Karte kein Abonnement. Bitte wenden Sie sich an Ihre Bank oder wählen Sie eine andere Zahlungsmethode aus.',
  subscription_set_successfully: 'Das Abonnement wurde korrekt eingerichtet.',
  booking_created: 'Neue Buchung',
  booking_updated: 'Buchungsänderung',
  booking_deleted: 'Buchungsstornierung',
  booking_reminder: 'Buchungserinnerung',
  email_notifications: 'E-mail Benachrichtigung',
  sms_notifications: 'SMS-Benachrichtigung',
  save_user_preferences: 'Einstellungen speichern',
  free_spots: 'Verbleibend',
  no: 'Nein',
  yes: 'Ja',
  p24_inactive: 'Der Operator Przelewy24 kann nicht verwendet werden. Bitte wenden Sie sich an Ihren Zahlungsanbieter.',
  stripe_customer_invalid: 'Ungültige Stripe-Kunden-ID. Die Zahlungsmethode kann nicht verbunden werden.',
  stripe_resource_invalid: 'Das ausgewählte Produkt ist beim Zahlungsanbieter nicht verfügbar.',
  account_blocked_message: 'Das Konto ist inaktiv. Bitte wenden Sie sich an den Administrator.',
  time_zone: 'Zeitzone',
  no_spots_left: 'Reservierungslimit wurde erreicht. Buchung nicht mehr möglich.',
  employee_auth_required: 'Bitte melden Sie sich bei Ihrem Konto an oder verwenden Sie eine andere E-Mail-Adresse. Mitarbeiterkonten erfordern eine Anmeldung.',
  subpage_auth_required: 'Sie müssen angemeldet sein, um die Registerkarte anzuzeigen.',
  gross: 'Bruttobetrag.',
  subscription_login_info: 'Sie haben bereits ein Konto? Bitte melden Sie sich unten an, um Ihre Daten einzugeben.',
  change_date_time: 'Termin ändern',
  promo_code_checkbox: 'Ich habe einen Rabattcode',
  booking_promo_code_label: 'Geben Sie den Rabattcode ein',
  select_hour: 'Zeit wählen',
  id: 'Id',
  booking_rescheduled: 'Die Buchung wurde bearbeitet.',
  booking_rescheduled_fail: 'Diese Buchung kann nicht geändert werden.',
  max_user_bookings_error: 'Sie haben die maximale Anzahl an Buchungen überschritten. Bitte wählen Sie einen anderen Service oder kontaktieren Sie uns.',
  in_total: 'Summe',
  company: 'Unternehmen',
  individual: 'Privatperson',
  bulk_payment: 'Massenzahlung',
  make_bulk_payment: 'Massenzahlung',
  simple_store_product_confirmation_title: 'Füllen Sie das Kaufformular aus',
  select_service_type: 'Wählen Sie eine Serviceoption aus',
  add_to_calendar_button_label: 'Im Kalender speichern',
  login_to_use_service_with_subscription: 'Bitte loggen Sie sich in Ihr Konto ein, um einen Termin für diesen Service zu buchen.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Der gewählte Termin ist nicht verfügbar. Bitte wählen Sie einen anderen freien Termin. Stellen Sie bei der Buchung mehrerer Termine sicher, dass sich die Termine nicht gegenseitig ausschließen.',
  select_location_calendar_warning: 'Wählen Sie einen Ort, um verfügbare Zeiten zu laden.',
  selectable_location_online_title: 'Online',
  change: 'Ändern',
  show_less_hours_button: 'Weniger Stunden anzeigen',
  show_more_hours_button: 'Mehr Stunden anzeigen',
  add_another_button_title: 'Noch einen hinzufügen',
  close: 'Schließen',
  selected_time_slots: 'Ausgewählte Zeitfenster',
  select_location: 'Wählen Sie einen Standort',
  captcha_invalid_error_notification: 'Captcha-Verifizierung fehlgeschlagen. Versuchen Sie, die Seite neu zu laden, wenn das Problem weiterhin besteht.',
  verify_code_incorrect_error: 'Der eingegebene Code ist falsch.',
  verify_code_too_many_requests: 'Wir können derzeit keinen neuen Code generieren. Bitte versuchen Sie es später erneut oder verwenden Sie den Code, den wir bereits gesendet haben.',
  send_again_button_title: 'Erneut senden',
  verify_title: 'Verifizieren',
  verify_code_title: 'Verifizierungscode eingeben',
  verify_code_description: 'Wir haben Ihnen einen 6-stelligen Verifizierungscode an Ihre E-Mail gesendet. Bitte geben Sie ihn unten ein:',
  verification_invalid_error_notification: 'Ungültige Überprüfung. Versuchen Sie es später noch einmal.'
}
